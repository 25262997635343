import { storyblokEditable } from "@storyblok/react";

const CustomerContainer = ({ blok }) => {
  return (
    <div
      className="mx-auto my-0 flex items-center justify-center bg-[#1b1f22] px-0 py-10"
      {...storyblokEditable(blok)}
    >
      <div className="flex w-4/5 flex-wrap items-center justify-center gap-9">
        {blok.images.map((image) => {
          if (image.component === "text") {
            return (
              <div
                className="flex w-full justify-center text-[1.6rem] font-normal text-[#cecfd2]"
                key={image._uid}
              >
                {image.text}
              </div>
            );
          }
          return (
            <img
              key={image._uid}
              src={image.url.filename}
              alt={image.url.alt || "Customer logo"}
              className="max-h-9 rounded"
            />
          );
        })}
      </div>
    </div>
  );
};

export default CustomerContainer;
