import { storyblokEditable } from "@storyblok/react";

const Footer = ({ blok }) => (
  <div className="footer" {...storyblokEditable(blok)}>
    <div className="flex flex-col items-center justify-center px-0 py-[140px]">
      <div className="my-12">
        <h1 className="text-center text-7xl font-medium leading-snug">
          {blok.heading}
        </h1>
        <h1 className="text-center text-7xl font-medium leading-snug text-[#1e6add]">
          {blok.textCarousel.split(", ")[0]}
        </h1>
      </div>
      <button className="cursor-pointer rounded-lg border-[none] bg-[linear-gradient(_145deg,#1e6add,33.3%,#399de4_63%,#55c8eb_)] px-10 py-4 text-base font-medium text-[white]">
        {blok.primaryCTA}
      </button>
    </div>

    <div className="bg-[#1b1f22] px-10 pt-10">
      <div className="flex justify-between pb-5">
        {/* <div className="flex justify-start"> */}
        <div>
          <img src={blok.logo.filename} alt="Logo" className="w-40" />
        </div>
        <div className="ml-[50px] flex flex-col items-start">
          {blok.product.map((item) => {
            if (item.component === "text") {
              return (
                <h3 className="font-[450] text-[#cecece]" key={item._uid}>
                  {item.text}
                </h3>
              );
            }
            return (
              <a
                key={item._uid}
                href={`/${item.url.cached_url}`}
                className="my-2 text-[#8f8f8f] no-underline"
              >
                {item.label}
              </a>
            );
          })}
        </div>
        <div className="ml-[50px] flex flex-col items-start">
          {blok.resources.map((item) => {
            if (item.component === "text") {
              return (
                <h3 className="font-[450] text-[#cecece]" key={item._uid}>
                  {item.text}
                </h3>
              );
            }
            return (
              <a
                key={item._uid}
                href={`/${item.url.cached_url}`}
                className="my-2 text-[#8f8f8f] no-underline"
              >
                {item.label}
              </a>
            );
          })}
        </div>
        <div className="ml-[50px] flex flex-col items-start">
          {blok.company.map((item) => {
            if (item.component === "text") {
              return (
                <h3 className="font-[450] text-[#cecece]" key={item._uid}>
                  {item.text}
                </h3>
              );
            }
            return (
              <a
                key={item._uid}
                href={`/${item.url.cached_url}`}
                className="my-2 text-[#8f8f8f] no-underline"
              >
                {item.label}
              </a>
            );
          })}
        </div>
        {/* </div> */}
        <div className="mt-4">
          <a
            href={blok.secondaryCTA.url}
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer rounded-lg border-[none] px-10 py-3.5 font-medium text-[white] shadow-secondary"
          >
            {blok.secondaryCTA?.label || "Call with founder"}
          </a>
        </div>
      </div>
      <div className="flex items-center justify-between border-t border-solid border-t-[#2d3339] px-5 py-3">
        <div className="flex gap-4">
          {blok.socials.map((item) => {
            if (item.component === "text") {
              return <div key={item._uid}></div>;
            }
            return (
              <a
                key={item._uid}
                href={item.URL.url}
                className="my-2 flex h-9 w-9 items-center justify-center rounded-full bg-[#2d3339] p-2.5"
              >
                <img
                  src={item.imageURL.filename}
                  alt={item.imageURL.alt || "Social Media Icon"}
                />
              </a>
            );
          })}
        </div>
        <p className="text-sm text-[#cecfd2]">{blok.copyright}</p>
      </div>
    </div>
  </div>
);

export default Footer;
