import { storyblokEditable } from "@storyblok/react";
// import { ImageContainer } from "./ImageContainer";
import { useState } from "react";

const CardsContainer = ({ blok }) => {
  return (
    <div className={`mx-auto px-12 py-[120px]`} {...storyblokEditable(blok)}>
      <Cards bordered={blok.borderedCards} cards={blok.cards} />
    </div>
  );
};

const Cards = ({ bordered, cards }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const defineSticky = (elementIndex) => (elementIndex + 1) * 100;
  return cards.map((card, index) =>
    bordered ? (
      <div
        className={`sticky my-4 flex flex-col gap-9 rounded-md border border-[#CECFD2] p-12 z-[${index + 2}] bg-[white]`}
        key={`${card._uid}-${index}`}
        style={{ top: `${defineSticky(index)}px` }}
      >
        <div className="flex items-center gap-2.5">
          <img src={card.icon.filename} alt={card.title} className="h-8 w-8" />
          <h2 className="text-[32px] tracking-wide text-[#2d3339]">
            {card.title}
          </h2>
        </div>
        <div className="flex items-center justify-between">
          <div className="w-1/3 text-[#2D3339]">
            {card.description.content.map((element, index) => {
              if (element.type === "paragraph") {
                return (
                  <p className="leading-[1.6] tracking-[0.5px]" key={index}>
                    {element.content[0].text}
                  </p>
                );
              } else if (element.type === "bullet_list") {
                return (
                  <ul
                    className="ml-4 leading-[1.6] tracking-[0.5px]"
                    key={index}
                  >
                    {element.content.map((item, itemIndex) => (
                      <li className="list-disc" key={itemIndex}>
                        {item.content[0].content[0].text}
                      </li>
                    ))}
                  </ul>
                );
              }
              return null;
            })}
            {card.images.length > 1 && (
              <div>
                {card.images.map((image, index) => (
                  <button
                    key={`${index}-dot`}
                    className={`m-2 cursor-pointer rounded border p-2 ${index === currentImageIndex ? "border-[#1e6add]" : "border-[#cecfd2]"}`}
                    onClick={() => {
                      setCurrentImageIndex(index);
                    }}
                  >
                    {image.alt || index}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className="w-2/4">
            {card.images.length > 1 ? (
              <img
                key={card.images[currentImageIndex].id}
                src={card.images[currentImageIndex].filename}
                alt={card.images[currentImageIndex].alt}
                className="h-[370px] w-full cursor-pointer"
              />
            ) : (
              <img
                key={card.images[0].id}
                src={card.images[0].filename}
                alt={card.images[0].alt}
              />
            )}
          </div>
        </div>
      </div>
    ) : (
      <div key={card._uid}>without border</div>
    ),
  );
};

export default CardsContainer;
