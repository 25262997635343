import { storyblokEditable } from "@storyblok/react";
import { Tweet } from "react-twitter-widgets";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const TwitterContainer = ({ blok }) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className="mx-auto my-0 flex flex-col items-center bg-[#f0f1f1] px-0 py-28"
    >
      <h2 className="my-8 border-solid text-[2.5rem] font-normal text-[#2d3339]">
        {blok.heading}
      </h2>
      <p className="mb-12 text-center text-lg leading-8 text-[#2d3339]">
        {blok.subheading} {blok.subheading}
      </p>
      <div className="mx-auto my-0 w-3/4 px-0 py-2">
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry columnsCount={3}>
            {blok.links.map((link) => (
              <div key={link._uid} className="w-11/12">
                <Tweet tweetId={link.url.url.split("/").pop()} />
              </div>
            ))}
            {blok.links.map((link) => (
              <div key={`${link._uid}-temp`} className="w-11/12">
                <Tweet tweetId={link.url.url.split("/").pop()} />
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  );
};

export default TwitterContainer;
