import React, { useState } from "react";
import { storyblokEditable } from "@storyblok/react";

const Header = ({ blok }) => {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <div
      className="fixed left-0 z-[100] box-border flex w-full transform-none items-center justify-between bg-[rgba(255,255,255,0.1)] px-[10%] py-5 backdrop-blur-sm"
      {...storyblokEditable(blok)}
    >
      <div className="flex items-center gap-7">
        <a href="/">
          <img src={blok.logo.filename} alt="Logo" className="mb-1" />
        </a>
        <nav className="flex gap-3">
          {blok.navigationLinks.map((navItem, index) => (
            <div key={navItem._uid} className="mr-2.5 inline-block">
              <button
                className="border-[none] font-medium text-[#2d3339]"
                onClick={() => toggleDropdown(index)}
              >
                <div className="mr-1 flex items-center justify-center">
                  {navItem.dropdownLabel}
                  <img src={navItem.icon.filename} alt="Dropdown Icon" />
                </div>
              </button>
              {activeDropdown === index && (
                <div className="absolute mt-1 flex flex-col rounded border border-solid border-[#cecfd2] bg-[white] p-4">
                  <p className="my-3 text-xs font-medium uppercase text-[#85888e]">
                    {navItem.dropdownLabel === "Product" ? "features" : "docs"}
                  </p>
                  {navItem.dropdownOptions.map((option) => (
                    <a
                      key={option._uid}
                      href={`/${option.url.cached_url}`}
                      className="mt-1 flex items-center gap-2 p-1 text-[#61646c] no-underline"
                    >
                      {option.image && (
                        <img
                          className="w-7"
                          src={option.image.filename}
                          alt="Dropdown Icon"
                        />
                      )}
                      {option.label}
                    </a>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>
      <button className="cursor-pointer rounded-lg border-[none] bg-[linear-gradient(_145deg,#1e6add,33.3%,#399de4_63%,#55c8eb_)] px-10 py-3.5 font-medium text-[white]">
        {blok.signupButton}
      </button>
    </div>
  );
};

export default Header;
