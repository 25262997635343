import { useState, useRef, useEffect } from "react";
import { storyblokEditable } from "@storyblok/react";

const HeroSection = ({ blok }) => {
  const [isDragging, setIsDragging] = useState(false);
  const dragStartX = useRef(0);
  const dividerRef = useRef(null);
  const backgroundRef = useRef(null);

  const [left, setLeft] = useState(() => {
    // Set the initial left position to 50% of the container width
    if (typeof window !== "undefined") {
      return window.innerWidth * 0.4; // 80% * 0.5 = 40% of total width
    }
    return 0;
  });

  useEffect(() => {
    const handleDragMove = (e) => {
      e.preventDefault();
      if (!isDragging) return;

      const clientX = e.type.includes("touch")
        ? e.touches[0].clientX
        : e.clientX;
      const dx = clientX - dragStartX.current;
      setLeft((prevLeft) => {
        const newLeft = prevLeft + dx;
        const containerWidth = dividerRef.current.parentElement.offsetWidth;
        return Math.max(0, Math.min(newLeft, containerWidth - 10)); // Adjust 10 to the width of your divider
      });
      dragStartX.current = clientX;
    };

    const handleDragEnd = () => {
      setIsDragging(false);
    };

    const addEventListeners = () => {
      document.addEventListener("mousemove", handleDragMove);
      document.addEventListener("touchmove", handleDragMove);
      document.addEventListener("mouseup", handleDragEnd);
      document.addEventListener("touchend", handleDragEnd);
    };

    const removeEventListeners = () => {
      document.removeEventListener("mousemove", handleDragMove);
      document.removeEventListener("touchmove", handleDragMove);
      document.removeEventListener("mouseup", handleDragEnd);
      document.removeEventListener("touchend", handleDragEnd);
    };

    if (isDragging) {
      addEventListeners();
    }

    return removeEventListeners;
  }, [isDragging]);

  const handleDragStart = (e) => {
    e.preventDefault();
    setIsDragging(true);
    dragStartX.current = e.type.includes("touch")
      ? e.touches[0].clientX
      : e.clientX;
  };

  return (
    <div
      className="relative overflow-hidden bg-gradient-to-b from-[white] via-heroGradientCenter to-heroGradientEnd px-0 pb-[120px] pt-20 text-center"
      {...storyblokEditable(blok)}
      // style={{ backgroundImage: `url(${blok.heroBackground.filename})` }}
    >
      <div
        ref={backgroundRef}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          clipPath: "circle(20em at 10% 10%)",
          backgroundImage: `url(${blok.heroBackground.filename})`,
          backgroundSize: "cover",
        }}
        onMouseEnter={(e) => {
          if (backgroundRef.current) {
            backgroundRef.current.style.clipPath = `circle(20em at ${e.nativeEvent.offsetX}px ${e.nativeEvent.offsetY}px)`;
          }
        }}
        onMouseMove={(e) => {
          if (backgroundRef.current) {
            backgroundRef.current.style.clipPath = `circle(20em at ${e.nativeEvent.offsetX}px ${e.nativeEvent.offsetY}px)`;
          }
        }}
        onMouseLeave={() => {
          if (backgroundRef.current) {
            backgroundRef.current.style.clipPath = "circle(20em at 10% 10%)";
          }
        }}
      />
      <div className="z-10">
        <h1 className="mx-auto my-0 mb-4 mt-24 w-3/5 text-[52px] font-medium leading-normal text-[#2d3339]">
          {blok.heading}
        </h1>
        <p className="mx-auto my-0 mb-12 w-6/12 animate-[typing_4s_ease-in-out] overflow-hidden whitespace-nowrap text-xl leading-normal text-[#61646c]">
          {blok.subheading}
        </p>
        <div className="mx-0 my-8 flex justify-center gap-5">
          {blok.cta.map((button) => (
            <button
              key={button._uid}
              className={`cursor-pointer rounded-lg border-[none] ${button.type === "primary" ? "bg-[linear-gradient(_145deg,#1e6add,33.3%,#399de4_63%,#55c8eb_)] text-[white]" : "shadow-secondary"} px-10 py-3.5 font-medium`}
            >
              {button.text}
            </button>
          ))}
        </div>
        <div className="relative mx-auto my-5 flex w-4/5 justify-center">
          {blok.heroAssets.map((asset, index) => {
            if (asset.title === "divider") {
              return (
                <div
                  key={asset.id}
                  ref={dividerRef}
                  className="absolute z-[6] h-full w-1 cursor-ew-resize bg-[black]"
                  style={{
                    left: `${left}px`,
                  }}
                  onMouseDown={handleDragStart}
                  onTouchStart={handleDragStart}
                >
                  <img
                    src={asset.filename}
                    alt={`Hero Asset ${index + 1}`}
                    className="absolute -left-350 top-2/4 w-8 max-w-none rounded-full bg-primary p-1"
                  />
                </div>
              );
            }

            return (
              <div
                key={asset.id}
                style={{
                  width: "100%",
                  height: "400px",
                  zIndex: index + 1,
                }}
              >
                <img
                  src={asset.filename}
                  alt={`Hero Asset ${index + 1}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                    backgroundColor:
                      index > 0 ? "rgba(182, 207, 248)" : "rgba(200, 217, 255)",
                    objectFit: "contain",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    clipPath:
                      index === 0 && dividerRef.current
                        ? `inset(0 ${
                            100 -
                            (left /
                              dividerRef.current.parentElement.offsetWidth) *
                              100
                          }% 0 0)`
                        : index === 2 && dividerRef.current
                          ? `inset(0 0 0 ${
                              (left /
                                dividerRef.current.parentElement.offsetWidth) *
                              100
                            }%)`
                          : "none",
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
