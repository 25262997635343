import { storyblokEditable } from "@storyblok/react";

const Grid = ({ blok }) => {
  return (
    <div className=" grid-container" {...storyblokEditable(blok)}>
      {blok.row.map((row, rowIndex) => {
        if (row.component === "text" && row.text === "Heading") {
          return (
            <h2 key={row._uid} style={{ textAlign: "center" }}>
              {row.text}
            </h2>
          );
        } else if (row.component === "text" && row.text === "subheading") {
          return (
            <p key={row._uid} style={{ textAlign: "center" }}>
              {row.text}
            </p>
          );
        }
        return (
          <div
            key={rowIndex}
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {row.column.map((item, columnIndex) => (
              <div
                key={item._uid}
                className="m-3 flex flex-col items-center rounded-lg bg-[white] p-5 shadow-[0_0_2px_#94969c]"
                style={{
                  width: columnIndex % 2 ? "30%" : "50%",
                }}
              >
                <img
                  src={item.image.filename}
                  alt={item.name}
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <h3 style={{ marginTop: "10px" }}>{item.name}</h3>
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  {item.description.content[0].content.map((item, index) => (
                    <li key={index}>{item.content[0].content[0].text}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default Grid;
